import Axios from '@/services/Axios';
import { ErrorResponse } from '../models/ErrorResponse';

export default {
  async auth(username, password, storeId) {
    const payload = { username, password, storeId };
    let response = null;

    try {
      response = await Axios.post('/login', payload);
    } catch (e) {
      console.error(e.response.data.message);
      response = new ErrorResponse(e.response.data.message);
      return response;
    }
    response = response.data;

    return response;
  },

  async authAdmin(username, password) {
    const payload = { username, password };
    let response = null;

    try {
      response = await Axios.post('/admin-login', payload);
    } catch (e) {
      console.error(e.response.data.message);
      response = new ErrorResponse(e.response.data);
      return response;
    }
    response = response.data;

    return response;
  },
};
