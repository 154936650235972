<template>
  <v-dialog @click:outside="closeModal"
            v-model="orderDetailsModal"
            max-width="1000">
    <v-card v-if="orderDetails">
      <v-card-title class="headline text-center">
        Detalles de la orden de compra #{{ orderDetails.id }}
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4">
            <p class="card-small-title">
              <v-icon>mdi-calendar</v-icon>
              Fecha
            </p>
            <p class="card-title-info text--primary">{{ orderDetails.createdDtStr }}</p>
          </v-col>

          <v-col cols="12" sm="4">
            <p class="card-small-title">
              <v-icon>mdi-office-building</v-icon>
              Cliente
            </p>
            <p class="card-title-info text--primary">{{ orderDetails.clientName }}</p>
          </v-col>

          <v-col cols="12" sm="4">
            <p class="card-small-title">
              <v-icon>mdi-account</v-icon>
              Vendedor
            </p>
            <p class="card-title-info text--primary">{{ orderDetails.employeeName }}</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12">
            <h2>Articulos</h2>
          </v-col>
        </v-row>

        <v-row v-for="(item, index) in orderItems"
               :key="index" class="item-list-item">
          <v-col v-if="item.isGift !== 1" cols="12" sm="1" class="text-center">
            {{ item.quantity }}
          </v-col>
          <v-col v-else cols="12" sm="1" class="text-center">
            - {{ item.quantity }}
          </v-col>
          <v-col cols="12" sm="6" class="pb-0">
            <p class="title">
            <span class="gift-label" v-if="item.isGift">
              (PROMO)
            </span> {{ item.name }}
            </p>
          </v-col>

          <v-col cols="12" sm="3" class="text-right pb-0">
            <p v-if="item.isGift !== 1" class="title pb-0">
              {{ item.costStr }}
            </p>
            <p v-else class="title">- {{ item.costWithDiscountStr }}</p>
          </v-col>

          <v-col v-if="item.calculatedDiscount > 0
          && item.isGift !== 1" cols="12" sm="7" class="pb-1 pt-0">
            <p class="mb-0">
              Descuento del producto
            </p>
          </v-col>

          <v-col v-if="item.calculatedDiscount > 0" cols="12" sm="3" class="text-right pb-1 pt-0">
            <p v-if="item.isGift !== 1" class="mb-0">- {{ item.discountStr }}</p>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col cols="12" sm="10">
            <v-col cols="12" sm="12" class="text-right total-payment-label">
              <h2>Total</h2>
            </v-col>
            <v-col cols="12" sm="12" class="text-right total-payment">
              <h1 class="">{{orderDetails.totalStr}}</h1>
            </v-col>
          </v-col>
        </v-row> -->

        <v-row justify="end" class="mr-8 total-section">
          <v-col cols="12" sm="2" class="text-right pt-0 pb-0">
            <p class="mb-0 text-right">
              <v-icon>mdi-currency-usd</v-icon>
              Total orden
            </p>
            <p class="display-1 text--primary mb-0">{{ orderDetails.totalAmountStr }}</p>
          </v-col>

          <v-col cols="12" sm="2" class="text-right pt-0 pb-0">
            <p class="mb-0">
              <v-icon>mdi-cash</v-icon>
              Efectivo
            </p>
            <p class="display-1 text--primary mb-0">{{ orderDetails.cashAmountStr }}</p>
          </v-col>

          <v-col cols="12" sm="2" class="text-right pt-0 pb-0">
            <p class="mb-0 text-right">
              <v-icon>mdi-credit-card</v-icon>
              Tarjeta débito
            </p>
            <p class="display-1 text--primary mb-0">{{ orderDetails.debitCardAmountStr }}</p>
          </v-col>

          <v-col cols="12" sm="2" class="text-right pt-0 pb-0">
            <p class="mb-0 text-right">
              <v-icon>mdi-credit-card</v-icon>
              Tarjeta crédito
            </p>
            <p class="display-1 text--primary mb-0">{{ orderDetails.creditCardAmountStr }}</p>
          </v-col>

          <v-col cols="12" sm="2" class="text-right pt-0 pb-0">
            <p class="mb-0 text-right">
              <v-icon>mdi-checkbook</v-icon>
              Cheque
            </p>
            <p class="display-1 text--primary mb-0">{{ orderDetails.checkAmountStr }}</p>
          </v-col>

          <v-col cols="12" sm="2" class="text-right pt-0 pb-0">
            <p class="mb-0 text-right">
              <v-icon>mdi-transfer-right</v-icon>
              Transferencia
            </p>
            <p class="display-1 text--primary mb-0">{{ orderDetails.transferAmountStr }}</p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title class="headline text-center">
        Error
      </v-card-title>

      <v-card-text>
        <v-row>
          <p class="display-1 text--primary">
            Ha ocurrido un problema al cargar los datos.
          </p>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'orderDetails',
    ]),
    orderDetailsModal: {
      get() {
        return this.$store.getters.orderDetailsModal;
      },
      // eslint-disable-next-line no-unused-vars
      set(value) {
        this.$store.dispatch('closeModal');
      },
    },
    orderItems() {
      return this.orderDetails.productsData;
    },
  },
  methods: {
    closeModal() {
      this.$store.dispatch('closeModal');
    },
  },
};
</script>

<style lang="less" scoped>
.item-list-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0;
  padding-top: 0.8rem;
orderItems
  p.title {
    font-size: 1.4rem;
    font-weight: 400;
    margin: 0;
  }
}

.gift-label {
  color: #4ed289;
  font-weight: 500;
}

.display-1 {
  font-size: 1.6rem !important;
}

.total-section {
  margin-top: 2rem;
}
</style>
