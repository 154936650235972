<template>
  <v-card class="elevation-2">
    <v-card-text class="pb-0 pt-1">
      <v-row>
        <v-col cols="12" sm="3">
          <p class="card-small-title">
            <v-icon>mdi-pound</v-icon>
            Número de orden compra
          </p>
          <p class="card-title-info text--primary">{{ data.id }}</p>
        </v-col>

        <v-col cols="12" sm="3">
          <p class="card-small-title">
            <v-icon>mdi-calendar</v-icon>
            Fecha
          </p>
          <p class="card-title-info text--primary">{{ data.createdDtStr }}</p>
        </v-col>

        <v-col cols="12" sm="3">
          <p class="card-small-title">
            <v-icon>mdi-office-building</v-icon>
            Cliente
          </p>
          <p class="card-title-info text--primary">{{ data.clientName }}</p>
        </v-col>

        <v-col cols="12" sm="3">
          <p class="card-small-title">
            <v-icon>mdi-account</v-icon>
            Vendedor
          </p>
          <p class="card-title-info text--primary">{{ data.employeeName }}</p>
        </v-col>
      </v-row>

      <v-row justify="end" class="mr-8">
        <v-col cols="12" sm="2" class="text-right pt-0 pb-0">
          <p class="mb-0 text-right">
            <v-icon>mdi-currency-usd</v-icon>
            Total orden
          </p>
          <p class="display-1 text--primary mb-0">{{ data.totalAmountStr }}</p>
        </v-col>

        <v-col cols="12" sm="2" class="text-right pt-0 pb-0">
          <p class="mb-0">
            <v-icon>mdi-cash</v-icon>
            Efectivo
          </p>
          <p class="display-1 text--primary mb-0">{{ data.cashAmountStr }}</p>
        </v-col>

        <v-col cols="12" sm="2" class="text-right pt-0 pb-0">
          <p class="mb-0 text-right">
            <v-icon>mdi-credit-card</v-icon>
            Tarjeta débito
          </p>
          <p class="display-1 text--primary mb-0">
            {{ data.debitCardAmountStr }}
          </p>
        </v-col>

        <v-col cols="12" sm="2" class="text-right pt-0 pb-0">
          <p class="mb-0 text-right">
            <v-icon>mdi-credit-card</v-icon>
            Tarjeta crédito
          </p>
          <p class="display-1 text--primary mb-0">
            {{ data.creditCardAmountStr }}
          </p>
        </v-col>

        <v-col cols="12" sm="2" class="text-right pt-0 pb-0">
          <p class="mb-0 text-right">
            <v-icon>mdi-checkbook</v-icon>
            Cheque
          </p>
          <p class="display-1 text--primary mb-0">{{ data.checkAmountStr }}</p>
        </v-col>

        <v-col cols="12" sm="2" class="text-right pt-0 pb-0">
          <p class="mb-0 text-right">
            <v-icon>mdi-transfer-right</v-icon>
            Transferencia
          </p>
          <p class="display-1 text--primary mb-0">
            {{ data.transferAmountStr }}
          </p>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="pb-0 pt-0">
      <v-row justify="end" class="pb-0 pt-0">
        <v-col cols="12">
          <v-dialog v-model="deleteOrderModal" width="500" persistent>
            <v-card>
              <v-card-title class="text-center justify-center text-h5 grey lighten-2">
                Eliminar orden de compra #{{data.id}}
              </v-card-title>

              <v-card-text v-if="!validUser">
                <v-container>
                  <v-row>
                    <v-col class="text-center grey-1--text text-h6" cols="12">
                      Ingrese credenciales de administrador para continuar
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        append-icon="mdi-account"
                        label="Usuario"
                        type="text"
                        v-model="username"
                        required

                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        :type="hidePassword ? 'password' : 'text'"
                        :append-icon="hidePassword ? 'mdi-eye-off' : 'mdi-eye'"
                        label="Contraseña"
                        ref="passwordInput"
                        @click:append="hidePassword = !hidePassword"
                        v-model="password"
                        @keypress.enter="authUser()"
                        required
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-text v-else>
                <v-container v-if="block === 0" class="text-center red--text text-h6">
                  ¿Está seguro que desea eliminar esta orden de compra?
                </v-container>

                <v-container v-if="block === 1" class="text-center red--text text-h6">
                  Espere un momento mientras se procesa su petición.
                </v-container>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions v-if="!validUser">
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="deleteOrderModal = false">
                  Cancelar
                </v-btn>

                <v-btn @click="authUser()" color="success" text >
                  Continuar
                </v-btn>
              </v-card-actions>
              <v-card-actions v-else>
                <v-spacer></v-spacer>
                <v-btn v-if="block === 0" color="error" text @click="deleteOrderModal = false">
                  Cancelar
                </v-btn>

                <v-btn v-if="block === 0" @click="deleteOrder(data.id)" color="success" text >
                  Confirmar
                </v-btn>

                <v-progress-linear
                  indeterminate
                  color="green"
                  v-if="block === 1"
                ></v-progress-linear>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="6" sm="2" class="pa-4">
          <v-btn
            @click="deleteOrderModal = true, validUser = false"
            class="pb-0 pt-0"
            text
            color="error"
          >
            Eliminar venta
          </v-btn>
        </v-col>

        <v-col cols="6" sm="2" class="pa-4">
          <v-btn
            @click="showOrderDetails(data)"
            class="pb-0 pt-0"
            text
            color="success"
          >
            Ver detalle
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import Swal from 'sweetalert2';
import AuthService from '../../services/AuthService';
import PurchaseOrderService from '../../services/PurchaseOrderService';

export default {
  name: 'purchase-order-card',

  props: ['data'],
  methods: {
    ...mapActions(['showOrderDetails']),

    async authUser() {
      this.validUser = false;
      if (!this.username) {
        Swal.fire('Error', 'Ingresa el usuario', 'error');
        return;
      }
      if (!this.password) {
        Swal.fire('Error', 'Ingresa la contraseña', 'error');
        return;
      }

      const resp = await AuthService.authAdmin(this.username, this.password);
      if (resp.error) {
        Swal.fire('Error', 'No se puede autenticar, favor de contactar con un administrador.', 'error');
        return;
      }
      this.validUser = true;
      this.username = '';
      this.password = '';
    },

    async deleteOrder(id) {
      this.block = 1;
      const resp = await PurchaseOrderService.deletePurchaseOrder(id);
      if (resp.error) {
        Swal.fire('Error', `${resp.error}`, 'error');
        this.block = 0;
        this.deleteOrderModal = false;
      } else {
        Swal.fire('Listo', `La orden de compra #${id} se ha eliminado exitosamente.`, 'success');
        this.block = 0;
        this.deleteOrderModal = false;
        this.$emit('receiveOrderId', id);
      }
    },
  },
  data: () => ({
    deleteOrderModal: false,
    username: '',
    password: '',
    hidePassword: true,
    loading: false,
    validUser: false,
    block: 0,
  }),

};
</script>

<style lang="less" scoped>
.card-small-title {
  margin-bottom: 0;
}

.card-title-info {
  font-size: 1.2rem;
}

.display-1 {
  font-size: 1.6rem !important;
}
</style>
