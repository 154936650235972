<template>
<v-card class="mx-auto" outlined elevation="1">
  <v-list-item>
    <v-list-item-avatar tile size="80" class="card-icon">
      <v-icon color="primary" x-large>{{icon}}</v-icon>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-subtitle>{{headerText}}</v-list-item-subtitle>
      <!-- <v-list-item-title class="headline mt-1">$ 1,200.00</v-list-item-title> -->
      <v-list-item-title class="headline mt-1">{{total}}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</v-card>
</template>

<script>
export default {
  name: 'money-total-card',
  props: ['icon', 'headerText', 'total'],
};
</script>

<style lang="less" scoped>
.card-icon {
  border: 1px solid #4ed289;
  background-color: #4ed2890a;
}
</style>
