<template>
<v-container class="pt-0 full-height">
  <v-row>
    <v-col>
      <h1 class="section-title">Corte de caja ({{ storeName }})</h1>
    </v-col>
  </v-row>

  <v-row v-if="!cashCloseData">
    <v-col cols="12" sm="4">
      <v-card class="mx-auto" outlined elevation="1">
        <v-skeleton-loader
        type="list-item-avatar-two-line">
        </v-skeleton-loader>
      </v-card>
    </v-col>
    <v-col cols="12" sm="4">
      <v-card class="mx-auto" outlined elevation="1">
        <v-skeleton-loader
        type="list-item-avatar-two-line">
        </v-skeleton-loader>
      </v-card>
    </v-col>
    <v-col cols="12" sm="4">
      <v-card class="mx-auto" outlined elevation="1">
        <v-skeleton-loader
        type="list-item-avatar-two-line">
        </v-skeleton-loader>
      </v-card>
    </v-col>
    <v-col cols="12" sm="4">
      <v-card class="mx-auto" outlined elevation="1">
        <v-skeleton-loader
        type="list-item-avatar-two-line">
        </v-skeleton-loader>
      </v-card>
    </v-col>
    <v-col cols="12" sm="4">
      <v-card class="mx-auto" outlined elevation="1">
        <v-skeleton-loader
        type="list-item-avatar-two-line">
        </v-skeleton-loader>
      </v-card>
    </v-col>
    <v-col cols="12" sm="4">
      <v-card class="mx-auto" outlined elevation="1">
        <v-skeleton-loader
        type="list-item-avatar-two-line">
        </v-skeleton-loader>
      </v-card>
    </v-col>
  </v-row>

  <!----------------- IMPORTES TOTALES (EFECTIVO, TARJETA) ------------------------->
  <v-row v-if="cashCloseData">
    <v-col cols="12" sm="4">
      <money-total-card icon="mdi-currency-usd"
      headerText="Total" :total="cashCloseData.totalAmountStr">
      </money-total-card>
    </v-col>

    <v-col cols="12" sm="4">
      <money-total-card icon="mdi-currency-usd"
      headerText="Total caja inicio" :total="cashCloseData.cashStartAmountStr">
      </money-total-card>
    </v-col>

    <v-col cols="12" sm="4">
      <money-total-card icon="mdi-cash"
      headerText="Total Efectivo en Caja" :total="cashCloseData.cashAmountStr">
      </money-total-card>
    </v-col>

    <v-col cols="12" sm="4">
      <money-total-card icon="mdi-credit-card"
      headerText="Total Tarjeta Débito" :total="cashCloseData.debitCardAmountStr">
      </money-total-card>
    </v-col>

    <v-col cols="12" sm="4">
      <money-total-card icon="mdi-credit-card"
      headerText="Total Tarjeta Crédito" :total="cashCloseData.creditCardAmountStr">
      </money-total-card>
    </v-col>

    <v-col cols="12" sm="4">
      <money-total-card icon="mdi-checkbook"
      headerText="Total Cheques" :total="cashCloseData.checkAmountStr">
      </money-total-card>
    </v-col>

    <v-col cols="12" sm="4">
      <money-total-card icon="mdi-transfer-right"
      headerText="Total Transferencias" :total="cashCloseData.transferAmountStr">
      </money-total-card>
    </v-col>

    <div class="justify-center" v-if="cashCloseData.orders.length > 0">
      <v-btn @click="makeCashClose" color="secondary">Hacer corte</v-btn>
    </div>
  </v-row>
  <!----------------- IMPORTES TOTALES (EFECTIVO, TARJETA) ------------------------->

  <v-row class="fill-height" v-if="cashCloseData">
    <v-col cols="12" sm="12" class="mt-4 pb-0">
      <h1 class="section-subtitle">Ordenes de compra</h1>
    </v-col>

    <v-col v-for="(item, index) in cashCloseData.orders" v-bind:key="index" cols="12" sm="12">
      <purchase-order-card @receiveOrderId="searchOrderId" :data="item"></purchase-order-card>
    </v-col>
  </v-row>

  <purchase-order-details-modal></purchase-order-details-modal>
</v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import PurchaseOrderService from '../services/PurchaseOrderService';
import MoneyTotalCard from '../components/CashClose/MoneyTotalCard.vue';
import PurchaseOrderCard from '../components/CashClose/PurchaseOrderCard.vue';
import PurchaseOrderDetailsModal from '../components/CashClose/PurchaseOrderDetailsModal.vue';
import { FPUtils } from '../utils/FloatingPointUtils';

export default {
  name: 'cash-closing',
  components: {
    MoneyTotalCard,
    PurchaseOrderCard,
    PurchaseOrderDetailsModal,
  },
  data: () => ({
    storeName: '',
    orderId: null,
    orderIndex: null,

  }),
  computed: {
    ...mapGetters({
      cashCloseData: 'cashCloseData',
      authUserData: 'authUserData',
    }),
  },
  methods: {
    async makeCashClose() {
      let { totalAmount } = this.cashCloseData;
      totalAmount = FPUtils.round2dec(totalAmount);

      const { store } = this.authUserData.user_claims;

      Swal.fire({
        allowOutsideClick: false,
        title: 'Proceder a Corte de caja',
        confirmButtonText: 'Si',
        text: '¿Hacer corte de caja? Esta operación no se puede deshacer',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        showLoaderOnConfirm: true,
        preConfirm: () => PurchaseOrderService.CashClose(totalAmount, store)
          .then((resp) => {
            if (!resp.error) {
              Swal.fire('Correcto', 'Se hizo el corte de caja', 'success');
              this.setupData();
            } else {
              Swal.fire({
                title: 'Error',
                text: 'No se pudo hacer el corte de caja',
                icon: 'error',
              });
            }
          }),
      });
    },
    async setupData() {
      const { store } = this.authUserData.user_claims;
      const data = await PurchaseOrderService.getCashCloseData(store);
      this.$store.dispatch('setCashCloseData', data);
    },

    searchOrderId(id) {
      this.orderId = id;
      this.orderIndex = this.cashCloseData.orders.findIndex((x) => x.id === this.orderId);
      this.cashCloseData.orders.splice(this.orderIndex, 1);
    },
  },
  async mounted() {
    this.setupData();
    this.storeName = localStorage.getItem('storeName');
  },
};
</script>

<style scoped>
.fixed-row {
  position: fixed;
  z-index: 1;
}
.section-title {
  font-weight: 300;
  font-size: 2.5rem;
  border-bottom: 1px black solid;
}
.section-subtitle {
  font-weight: 300;
  font-size: 2rem;
}

.headline {
  font-size: 1.8rem!important;
}
.orders-container {
  display: flex !important;
  height: 100% !important;
  flex-flow: column !important;
}
</style>
